import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import styles from "./index.module.scss"
import r from "../../services/Router"
import { graphql, Link } from "gatsby"
import ThemeContext from "../../context/ThemeContext"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import localize from "../../components/localize"

const Content = ({ bonuses, locale }) => {
  const distinct = (value, index, self) => {
    return self.indexOf(value) === index
  }

  const filter = bonuses => {
    bonuses = bonuses
      .filter(x => NullIf.isNotNullOrEmpty(x))
      .map(x => x.group)
      .filter(x => NullIf.isNotNullOrEmpty(x))
      .map(x => x.name)
      .filter(distinct)
    return bonuses
  }

  let site = useContext(ThemeContext)
  let translations = site.translations

  return (
    <div className={`${styles.casinoGroups}`}>
      {filter(bonuses).map(x => (
        <p>
          <Link to={r.get(locale, translations, "casinoGroup", x)}>{x}</Link>
        </p>
      ))}
    </div>
  )
}

const Index = ({ location, pageContext, data }) => {
  let site = useContext(ThemeContext)
  const { locale, alternateLocaleSlugs } = pageContext
  let bonuses = site.bonuses

  let pageData = data.sanityGenericLandingPage

  return (
    <Layout  breadcrumbNameOverride={pageData.breadcrumbName} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
      />
      <GenericLandingContentWrapper pageData={pageData} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
        <Content bonuses={bonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(Index)
export const query = graphql`
  query CasinoOwnerLandingQuery {
    sanitySilo (_id: {eq: "15f98d4b-75f1-4ab5-9a2e-a87ce7a8383f"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "Casino Owner" }
    ) {
      ...GenericLanding
    }
  }
`
