import React, { useContext } from "react"
import RichText from "./rich-text"
import Img from "gatsby-image"
import styles from "./folded-content.module.scss"
import VerticalTabs from "../../components/vertical-tabs/vertical-tabs"
import HorizontalTabs from "../../components/horizontal-tabs/horizontal-tabs"
import TableOfContent from "../../components/table-of-content/table-of-content"
import ShowMoreText from 'react-show-more-text';
import t from '../../services/GetTranslationValue'
import ThemeContext from "../../context/ThemeContext"
import BlockContent from "./portable-text"

const ContentWrapper = ({
  banner,
  title,
  header,
  body,
  footer,
  children,
  headerClassName,
  bodyClassName,
  footerClassName,
  isVerticalTab,
  useFullBody,
  specialContent,
  specialHeader,
  bannerUrl,
  specialClassName
}) => {
  if (!headerClassName)
    headerClassName = `gambleGeneralWrapper`;
  if (!bodyClassName)
    bodyClassName = `gambleGeneralWrapper`;
  if (!footerClassName)
    footerClassName = `gambleGeneralWrapper`;

  const trim = (node) => {
    if (node && node.content){
      node.content = node.content.filter(x =>
        (x && x.content && x.content[0] && x.content[0].value && x.content[0].value.length !== 0)
      )
    }
    return node;
  }

  const {translations, locale} = useContext(ThemeContext);
  //
  // if (!header){
  //   header = {};
  // }
  // if (!useFullBody)
  //   header.json = trim(header.json);
  //
  // const useRichTextHeader = !title && header.json && header.json.content && header.json.content[0].nodeType === 'heading-1';
  // const headerText = title || (useRichTextHeader ? header.json.content[0].content[0].value : "");
  //
  // let h2FooterText = header.json && header.json.content && header.json.content[header.json.content.length - 1].nodeType === 'heading-2'
  //   ? header.json.content[header.json.content.length - 1].content[0].value : null
  //
  //
  // let headerResult = Object.assign({}, header.json);
  // if (useRichTextHeader){
  //   headerResult.content = header.json.content.filter((x, index) => x.nodeType !== 'heading-1');
  // }
  //
  // if (h2FooterText)
  //   headerResult.content = headerResult.content.filter((x, index) => index < header.json.content.length - 2);
  //
  function bodyExists() {
    if (body.some(x => x.style === "h2" || x.style === "h3" || x.style === "h4")) {
      return (
        <div className={`${styles.tocParent}`}>
          <TableOfContent pageContext={body} />
        </div>
      )
    }
  }
  
  function headingExists() {
    if (header.some(x => x.style === "h2" || x.style === "h3" || x.style === "h4")) {
      return (
        <div className={`${styles.tocParent}`}>
          <TableOfContent pageContext={header} />
        </div>
      )
    }
  }

  function footerExists() {
    if (footer.some(x => x.style === "h2" || x.style === "h3" || x.style === "h4")) {
      return (
        <div className={`${styles.tocParent}`}>
          <TableOfContent pageContext={footer} />
        </div>
      )
    }
  }

  if (!title){
    if (header) {
      const newHeader = header.find(x => x.style === "h1");
      if (newHeader){
        if (newHeader.children && newHeader.children[0])
        {
          title = newHeader.children.map(x => x.text).join('');
          header = header.filter(x => x.style !== 'h1');
        }
      }
    }
  }

  function executeOnClick(isExpanded) {
    // console.log(isExpanded);
  }

  return (
    <div className={specialClassName}>
      {banner && banner.asset && banner.asset.fluid && <Img alt={`${title}-banner`} fluid={banner.asset.fluid} />}
      {bannerUrl && <img className="special-banner" alt={`${title}-banner`} src={bannerUrl} />}
      {specialHeader && specialHeader}
      { <h1 className="contentWrapperTitle"> {title} </h1> }
      
      <div className="contentWrapperBody">
        {header ? headingExists() : "" }
        {body ? bodyExists() : "" }
        {footer ? footerExists() : "" }
        {header && (

          <div className={headerClassName}
               id={"top"}>
            {
              !useFullBody ?
                [<ShowMoreText
                  keepNewLines={false}
                  onClick={executeOnClick}
                anchorClass=''
                more={t.get(translations, 'show-more')}
                less={t.get(translations, 'show-less')}>
                  <BlockContent blocks={header} />
              </ShowMoreText>]
                :  <BlockContent blocks={header} />
            }

            {/*{h2FooterText &&*/}
              {/*<h2>{h2FooterText}</h2>*/}
            {/*}*/}
          </div>
        )}

        {children}
        {/* {JSON.stringify(header)} */}
        {/* always a blob of text */}
        {body && <BlockContent blocks={body} cnInternal={`${bodyClassName}`}/>}
        
        {specialContent && specialContent}
        
        {
          footer && <BlockContent blocks={footer}/>
        }

      </div>
    </div>
  )
}

export default ContentWrapper
