import React, { useState } from "react"
import styles from "./horizontal-tabs.module.scss"

import { Tab, Tabs, Typography } from "@material-ui/core"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import FoldedContent from "../text-helpers/folded-content"
import RichText from "../text-helpers/rich-text"

const HorizontalTabs = ({ json, casino, fallbackClassName }) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function TabsIndex(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    }
  }

  if (!json) return ""
  const content = json.content
  const titles = content
    .filter(node => node.data && node.data.target && node.data.target.fields)
    .map(node => node.data.target.fields.title && node.data.target.fields.title.fi)
    .filter(node => node)

  if (titles.length === 0)
    return <RichText node={json} cnInternal={fallbackClassName} />

  const body = content
    .filter(node => node.data && node.data.target && node.data.target.fields)
    .map(
      node =>
        node.data.target.fields &&
        node.data.target.fields.contentItem &&
        node.data.target.fields.contentItem.fi
    )
    .map(node => documentToReactComponents(node))

  const footer = content
    .filter(node => node.data && node.data.target && node.data.target.fields)
    .map(
      node =>
        node.data.target.fields &&
        node.data.target.fields.footerContentItem &&
        node.data.target.fields.footerContentItem.fi
    )
    .map(node => documentToReactComponents(node))

  return (
    <div className={`gamble-tab ${styles.tabsContainer}`}>
      <div className={`gamble-hidden-md-up mobile-folded-drop-downs ${styles.tabsContainer}`}>
        {body.map((x, index) => (
          <FoldedContent title={titles[index]}>
            {x}
          </FoldedContent>
        ))}
      </div>
      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={`gamble-hidden-md-down ${styles.tabs}`}
        centered
      >
        {titles.map((x, index) => (
          <Tab
            label={x}
            {...TabsIndex(index)}
            className={`${styles.tabTitle}`}
          />
        ))}
      </Tabs>
      {body.map((x, index) => (
        <TabPanel
          value={value}
          index={index}
          className={`gamble-hidden-md-down ${styles.tabContent}`}
        >
          <div className={`${styles.tabBody}`}>
            {x}
            {footer && footer[index]}
          </div>
        </TabPanel>
      ))}
    </div>
  )
}
export default HorizontalTabs
