import React from "react"
import ContentWrapper from "./content-wrapper"

const GenericLandingContentWrapper = ({
  pageData,
  children,
  headerClassName,
  bodyClassName,
  footerClassName,
  isVerticalTab,
  useFullBody,
  banner,
  specialContent
}) => {
  if (!pageData) pageData = {}
  const {
    _rawHeaderText: header,
    _rawCombinedBody: body,
    _rawFooterText: footer,
  } = pageData

  return [
    <ContentWrapper
      title={pageData.h1Tag}
      banner={banner}
      header={header}
      body={body}
      footer={footer}
      headerClassName={headerClassName}
      bodyClassName={bodyClassName}
      footerClassName={footerClassName}
      isVerticalTab={isVerticalTab}
      useFullBody={useFullBody}
      specialContent={specialContent}
    >
      {children}
    </ContentWrapper>,
  ]
}

export default GenericLandingContentWrapper
