import React, { useContext, useState } from "react"
import styles from "./table-of-content.module.scss"
import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import h from '../../services/GenericHelpers'
import ThemeContext from "../../context/ThemeContext"
import t from "../../services/GetTranslationValue"


const TableOfContent = ({pageContext}) => {

  const [tableVisible, setTableVisible] = useState(false);

  const openTable = () => {
    setTableVisible(!tableVisible);
  };

  const site = useContext(ThemeContext)
  let { translations } = site

  function getGroupedContent() {

    return pageContext.map((x, index) => {
      
      if (x.style === "h2") {
        return (
          <li key={`#${h.removeAccents(x.children[0].text.replace(/\s/g, "-"))}`} className={`${styles.tableTitle}`}>
            <ArrowRightIcon />
            <a href={`#${h.removeAccents(x.children[0].text.replace(/\s/g, "-"))}`}>{x.children[0].text}</a>
          </li>
        )
      }
      else if (x.style === "h3" || x.nodeType === "h4") {
        return (
          <li key={`#${h.removeAccents(x.children[0].text.replace(/\s/g, "-"))}`} className={`${styles.tableSubTitle}`}>
            <ArrowRightIcon />
            <a href={`#${h.removeAccents(x.children[0].text.replace(/\s/g, "-"))}`}>{x.children[0].text}</a>
          </li>
        )
      }
    })
  }

  

  return (
    <div className={`${styles.mainContainer} ${tableVisible? styles.tableOpen : ""}`}>
      <Button 
        variant="contained" 
        className={`${styles.tableButton}`}
        onClick={openTable} 
        color="primary"
        aria-label={t.get(translations, "table of content title")}
      >
        <ListAltIcon />
      </Button>
      <div className={`${styles.tableContainer}`}>
        <p>{t.get(translations, "table of content title")}</p>
        <ul className={`${styles.tableOfContent}`}>
          {getGroupedContent()}
        </ul>
      </div>
    </div>
  )
}
export default TableOfContent
