import React from "react"
import { createLocaleTextGetter, localizeItem } from "../graphql-assistant/createLocaleTextGetter"

function localize(Component) {
  return class Localize extends React.Component {

    constructor(props) {
      super(props)
      this.getLocalizedContent = createLocaleTextGetter(
        this.props.pageContext && this.props.pageContext.locale || 'fi'
      )
    }
    render() {
      return (
        <Component
          {...this.props}
          originalData={this.props.data}
          data={localizeItem(this.props.data, [this.props.pageContext && this.props.pageContext.locale || 'fi'])}
        />
      )
    }
  }
}
export default localize